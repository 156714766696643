<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
          type="button"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>
    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              v-if="!isNativeApp"
              class="btn-outline primary-btn me-3 d-flex my-1"
              @click="$bvModal.show('export-modal')"
            >
              <b-icon-upload font-scale="1.5" style="margin-right: 5px" />
              <p>{{ $t("depreciation.export_file") }}</p>
            </button>
            <select
              class="form-control w-auto me-3 my-1"
              v-model="infoYear"
              @change="loadAssetData"
            >
              <option
                v-for="year in yearList"
                :key="year.value"
                :value="year.value"
                >{{ year.label }}</option
              >
            </select>
          </div>
          <div class="search-box d-flex" style="border-radius: 10px;">
            <img src="@/assets/search-white.svg" width="20px" />
            <input
              type="text"
              v-debounce:700ms="searchByText"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
        </div>
        <SoldTable
          :assetData="assetData"
          :otherColumn="otherColumn"
          :page="currentPage"
          :perPage="perPage"
          rowSelection="none"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import SoldTable from "../../components/Table/Depreciation/SoldAssetTable.vue";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
export default {
  name: "SoldAsset",
  components: {
    SoldTable,
    Loading,
  },
  data() {
    return {
      accounting_period: moment().startOf('year'),
      isLoading: true,
      company_id: null,
      infoYear: new Date().getFullYear(),
      assetData: [],
      otherColumn: [],
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      currentPage: 1,
      totalRows: 1,
      perPage: 50,
      accountYear: null,
      accountMonth: 1,
      yearList: [],
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      isNativeApp: "isNativeApp",
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
  },
  watch: {
    currentPage() {
      this.loadAssetData();
    },
  },
  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const filename = `${this.$t("depreciation.sold_asset")}_${
          this.companyName
        }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`;
        const res = await axios.get(
          `${baseUrl()}depreciation/export/write_off/approved/list/${this.company_id}`,
          {
            params: {
              period_date: this.infoYear,
              text:
                this.searchText && this.searchText !== ""
                  ? this.searchText
                  : null,
            },
            ...authHeader(),
            responseType: "blob",
          }
        );
        if (!isMail) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const elink = document.createElement("a");
          elink.download = filename;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}depreciation/write_off/approved/list/${this.company_id}`,
          {
            params: {
              page: this.currentPage,
              limit: this.perPage,
              period_date: this.infoYear,
              text:
                this.searchText && this.searchText !== ""
                  ? this.searchText
                  : null,
            },
            ...authHeader(),
          }
        );
        this.totalRows = res.data.count;
        this.assetData = res.data.rows;
        this.otherColumn = res.data.other_columns;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    setYearList() {
      const accountingYear = this.accountYear;
      const list = [];
      const accountingMonth = this.accountMonth - 1;
      const currentYear = new Date().getFullYear();
      if (accountingMonth > 0) {
        let index = 0;
        while (this.accounting_period.get('year') <= currentYear) {
          if (
            moment().isSameOrAfter(
              moment(
                [accountingYear + index, accountingMonth + 1],
                "YYYY-M"
              ).startOf("M")
            )
          ) {
            list.push({
              label: `${this.$t(
                `adddate.month_short[${accountingMonth}]`
              )} ${accountingYear + index} - ${this.$t(
                `adddate.month_short[${accountingMonth - 1}]`
              )} ${accountingYear + 1 + index}`,
              value: this.accounting_period.format('YYYY-MM-DD'),
            });
          }
          index += 1;
          this.accounting_period.add(1, 'year')
        }
      } else {
        let index = 0;
        while (this.accounting_period.get('year') <= currentYear) {
          list.push({
            label: `${this.$t(
              `adddate.month_short[${accountingMonth}]`
            )} ${accountingYear + index} - ${this.$t(
              `adddate.month_short[11]`
            )} ${accountingYear + index}`,
            value: this.accounting_period.format('YYYY-MM-DD'),
          });
          index += 1;
          this.accounting_period.add(1, 'year')
        }
      }
      this.yearList = list;
      this.infoYear = list[list.length - 1].value;
    },
    async getCompanyData() {
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}company/getCompany/${this.company_id}`,
          authHeader()
        );
        this.accounting_period = moment(res.data.data.accounting_period)
        this.accountMonth = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("M")
          : 1;
        this.accountYear = moment(res.data.data.accounting_period).isValid()
          ? +moment(res.data.data.accounting_period).format("YYYY")
          : new Date().getFullYear();
        this.setYearList();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
    },
  },
  async mounted() {
    await this.getCompanyData();
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }

  &:disabled {
    color: #DDDDDD !important;
    border-color: #DDDDDD !important;
    cursor: not-allowed !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

/*
  Animation
*/
</style>
